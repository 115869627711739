import { NODE_ENV } from '~/constants';

type TMessageType = 'info' | 'error' | 'log' | 'warn';

type TLogEntry = {
  type: TMessageType;
  message: Array<unknown>;
};

const logFormatter = (
  text: Array<unknown>,
  type: TMessageType = 'info',
): TLogEntry | Array<unknown> => {
  if (NODE_ENV === 'development') {
    return text;
  }
  return {
    type,
    message: text,
  };
};

export const logger = {
  error: (...data: Array<unknown>): void => {
    console.log(logFormatter(data, 'error'));
  },
  info: (...data: Array<unknown>): void => {
    console.log(logFormatter(data, 'info'));
  },
  log: (...data: Array<unknown>): void => {
    console.log(logFormatter(data, 'log'));
  },
  warn: (...data: Array<unknown>): void => {
    console.warn(logFormatter(data, 'warn'));
  },
};
